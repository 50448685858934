var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "d-flex align-items-center list-group-item",
      staticStyle: { padding: "0px" },
    },
    [
      !_vm.isEditing
        ? _c(
            "div",
            { staticClass: "flex-grow-1" },
            [
              _c("div", { staticStyle: { "font-size": "12px" } }, [
                _vm._v(_vm._s(_vm.date)),
              ]),
              _c("v-textarea", {
                staticClass: "form-control",
                staticStyle: { width: "100%" },
                attrs: {
                  readonly: "",
                  type: "text",
                  filled: "",
                  rows: "1",
                  placeholder: "add multiple lines",
                  "auto-grow": "",
                },
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
            ],
            1
          )
        : _c(
            "form",
            {
              staticClass: "flex-grow-1",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.finishEditing()
                },
              },
            },
            [
              _c("v-textarea", {
                ref: "newNote",
                staticClass: "form-control mt-5",
                attrs: {
                  type: "text",
                  rows: "1",
                  outlined: "",
                  "auto-grow": "",
                },
                on: {
                  blur: function ($event) {
                    return _vm.finishEditing()
                  },
                },
                model: {
                  value: _vm.newNoteDescription,
                  callback: function ($$v) {
                    _vm.newNoteDescription = $$v
                  },
                  expression: "newNoteDescription",
                },
              }),
            ],
            1
          ),
      _c(
        "v-btn",
        {
          staticClass: "btn-default bg-gradient-success ml-2 mt-5",
          attrs: { icon: "" },
          on: {
            click: function ($event) {
              return _vm.startEditing()
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-pencil")])],
        1
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.archived,
              expression: "!archived",
            },
          ],
          staticClass: "btn-default bg-gradient-danger ml-1 mt-5",
          attrs: { icon: "", color: "error" },
          on: {
            click: function ($event) {
              return _vm.$emit("on-delete")
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-delete")])],
        1
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.archived,
              expression: "!archived",
            },
          ],
          staticClass: "btn-default bg-gradient-primary ml-1 mt-5",
          attrs: { icon: "", color: "primary" },
          on: {
            click: function ($event) {
              return _vm.$emit("on-archive")
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-book")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }