<template>
  <li class="d-flex align-items-center list-group-item" style="padding: 0px">
    <!-- <button
      class="btn border-0 flex-grow-1 text-left shadow-none"
      v-if="!isEditing"
    > -->
    <div v-if="!isEditing" class="flex-grow-1">
      <div style="font-size: 12px">{{ date }}</div>
      <v-textarea
        readonly
        type="text"
        filled
        rows="1"
        v-model="description"
        placeholder="add multiple lines"
        style="width: 100%"
        class="form-control"
        auto-grow
      ></v-textarea>
    </div>

    <!-- <span v-if="!isEditing"><div style="font-size: 12px;">{{ date }}</div> {{ description }}</span> -->
    <!-- </button> -->
    <form v-else class="flex-grow-1" @submit.prevent="finishEditing()">
      <v-textarea
        type="text"
        class="form-control mt-5"
        rows="1"
        outlined
        v-model="newNoteDescription"
        @blur="finishEditing()"
        ref="newNote"
        auto-grow
      ></v-textarea>
    </form>
    <v-btn @click="startEditing()" class="btn-default bg-gradient-success ml-2 mt-5" icon>
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn
      v-show="!archived"
      @click="$emit('on-delete')"
      icon
      class="btn-default bg-gradient-danger ml-1 mt-5"
      color="error"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>
    <v-btn
      v-show="!archived"
      @click="$emit('on-archive')"
      icon
      class="btn-default bg-gradient-primary ml-1 mt-5"
      color="primary"
    >
      <v-icon>mdi-book</v-icon>
    </v-btn>
  </li>
</template>

<script>
export default {
  data() {
    return {
      isEditing: false,
      newNoteDescription: "",
    };
  },
  props: {
    date: String,
    description: String,
    completed: Boolean,
    id: Number,
    archived: Boolean,
  },
  methods: {
    startEditing() {
      if (this.isEditing) {
        this.finishEditing();
      } else {
        this.newNoteDescription = this.description;
        this.isEditing = true;
        this.$nextTick(() => this.$refs.newNote.focus());
      }
    },
    finishEditing() {
      this.isEditing = false;
      this.$emit("on-edit", this.newNoteDescription);
    },
  },
};
</script>

<style lang="scss" scoped>
.completed {
  text-decoration: line-through;
}
</style>
